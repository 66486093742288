import React from "react";
const ribbonStyle = require('./ribbon.module.scss');
export const Ribbon = ({ status }) => {
    let label = null;
    let className = null;
    if (status === 'Cancelled') {
        label = "Abgesagt";
        className = ribbonStyle.ribbon_cancelled;
    }
    else if (status === 'Digital') {
        label = "Online";
        className = ribbonStyle.ribbon_digital;
    }
    else if (status === 'Postponed') {
        label = 'Verschoben';
        className = ribbonStyle.ribbon_postponed;
    }
    let element = (label && className) ?
        <div className={ribbonStyle.ribbon_holder}>
            <div className={ribbonStyle.ribbon_wraps}>
                <div className={`${ribbonStyle.ribbon_banner} ${className}`}>
                    {label}
                </div>
            </div>
        </div>
        : null;
    return element;
};
