import { kindToBackgroundColor, kindToString } from "../utils/kind";
import React from "react";
const kindStyle = require('./kind.module.scss');
export const Kind = ({ kind, height }) => {
    let style = { background: kindToBackgroundColor(kind) };
    if (height) {
        style.height = height;
    }
    return <div className={kindStyle.kind} style={style}>
        {kindToString(kind)}
    </div>;
};
