import React from "react";
export const urlDetect = (text) => {
    const p = [];
    let key = 0;
    let lastIndex = 0;
    let pattern = /https?:\/\/\S*/g;
    let match;
    while (match = pattern.exec(text)) {
        let matchedIndex = match.index;
        if (lastIndex < matchedIndex) {
            // push the previous string
            p.push(<React.Fragment key={key++}>{text.substring(lastIndex, matchedIndex)}</React.Fragment>);
        }
        let url = match[0];
        p.push(<a href={url} key={key++}>{url}</a>);
        lastIndex = pattern.lastIndex;
    }
    if (lastIndex < text.length) {
        // push the rest of the line
        p.push(<React.Fragment key={key++}>{text.substring(lastIndex, text.length)}</React.Fragment>);
    }
    return p;
};
const paragraphs = (lines) => {
    const p = [];
    let current = [];
    lines.forEach((line, _) => {
        if (/^\s*$/.test(line)) {
            // remove supplemental <br/>
            current.pop();
            p.push(current);
            current = [];
        }
        else {
            let lastIndex = 0;
            let pattern = /https?:\/\/\S*/g;
            let match;
            while (match = pattern.exec(line)) {
                let matchedIndex = match.index;
                if (lastIndex < matchedIndex) {
                    // push the previous string
                    current.push(line.substring(lastIndex, matchedIndex));
                }
                let url = match[0];
                current.push(<a href={url}>{url}</a>);
                lastIndex = pattern.lastIndex;
            }
            if (lastIndex < line.length) {
                // push the rest of the line
                current.push(line.substring(lastIndex, line.length));
            }
            // after each line, we push a line break.
            current.push(<br />);
        }
    });
    current.pop();
    if (current) {
        p.push(current);
    }
    return p;
};
export const formatText = (text) => {
    const lines = text.split("\n");
    const ps = paragraphs(lines);
    return ps.map((para, index) => {
        return <p key={index}>
            {para.map((el, index) => <React.Fragment key={index}>{el}</React.Fragment>)}
        </p>;
    });
};
