import moment from "moment";
import 'moment/locale/de-ch';
moment.locale("de-ch");
const divWithMod = (n1, n2) => {
    return [Math.floor(n1 / n2), n1 % n2];
};
export const toOrdinalDate = (rata) => {
    // 400 * 365 + 97
    const [n400, r400] = divWithMod(rata, 146097);
    // 100 * 365 + 24
    const [n100, r100] = divWithMod(r400, 36524);
    // 4 * 365 + 1
    const [n4, r4] = divWithMod(r100, 1461);
    const [n1, r1] = divWithMod(r4, 365);
    const n = r1 === 0 ? 0 : 1;
    return { year: n400 * 400 + n100 * 100 + n4 * 4 + n1 + n, ordinalDay: r1 };
};
const isLeapYear = (year) => ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
const daysInMonth = (year, monthIndex) => {
    switch (monthIndex) {
        case 0:
            return 31;
        case 1:
            return isLeapYear(year) ? 29 : 28;
        case 2:
            return 31;
        case 3:
            return 30;
        case 4:
            return 31;
        case 5:
            return 30;
        case 6:
            return 31;
        case 7:
            return 31;
        case 8:
            return 30;
        case 9:
            return 31;
        case 10:
            return 30;
        default:
            return 31;
    }
};
export const toCalendarDate = (rata) => {
    const { year, ordinalDay } = toOrdinalDate(rata);
    let monthIndex = 0;
    let day = ordinalDay;
    while (true) {
        const monthDays = daysInMonth(year, monthIndex);
        if (monthIndex >= 11 || day <= monthDays) {
            return { year: year, monthIndex: monthIndex, day: day };
        }
        monthIndex++;
        day -= monthDays;
    }
};
export const toDate = (rata) => {
    const { year, monthIndex, day } = toCalendarDate(rata);
    return new Date(year, monthIndex, day);
};
export const formatRataWeekDay = (rata) => {
    return moment(toDate(rata)).format('ddd');
};
export const formatRataWithoutWeekDay = (rata) => {
    return moment(toDate(rata)).format('DD. MMM');
};
export const formatRataYear = (rata) => {
    return moment(toDate(rata)).format('YYYY');
};
export const formatMinutes = (minutes) => {
    const min = Math.floor(minutes / 60);
    const sec = minutes % 60;
    return ('00' + min).slice(-2) + ":" + ('00' + sec).slice(-2);
};
function daysBeforeYear(mom) {
    const y = mom.year() - 1;
    const leapYears = Math.floor(y / 4) - Math.floor(y / 100) + Math.floor(y / 400);
    return y * 365 + leapYears;
}
function daysBeforeMonth(mom) {
    const leapDays = mom.isLeapYear() ? 1 : 0;
    switch (mom.month()) {
        case 0:
            return 0;
        case 1:
            return 31;
        case 2:
            return 59 + leapDays;
        case 3:
            return 90 + leapDays;
        case 4:
            return 120 + leapDays;
        case 5:
            return 151 + leapDays;
        case 6:
            return 181 + leapDays;
        case 7:
            return 212 + leapDays;
        case 8:
            return 243 + leapDays;
        case 9:
            return 273 + leapDays;
        case 10:
            return 304 + leapDays;
        default:
            return 334 + leapDays;
    }
}
export const toRata = (date) => {
    const mom = moment(date);
    return daysBeforeYear(mom) + daysBeforeMonth(mom) + mom.date();
};
export const todayInRata = () => {
    return toRata(new Date());
};
