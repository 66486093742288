export const kinds = [
    ["Theater", "Movie", "Music", "Cabaret", "Dance"],
    ["Exhibition", "Course", "Literature", "Science", "GuidedTour"],
    ["Food", "Leisure", "Party", "Sport", "Networking"],
    ["Miscellaneous"]
];
export const kindToString = (kind) => {
    switch (kind) {
        case "Movie":
            return "Film";
        case "Cabaret":
            return "Kleinkunst";
        case "Music":
            return "Musik";
        case "Dance":
            return "Tanz";
        case "Theater":
            return "Theater";
        case "Exhibition":
            return "Ausstellung";
        case "GuidedTour":
            return "Führung";
        case "Course":
            return "Kurs";
        case "Literature":
            return "Literatur";
        case "Science":
            return "Wissenschaft";
        case "Food":
            return "Essen";
        case "Leisure":
            return "Freizeit";
        case "Party":
            return "Party";
        case "Sport":
            return "Sport";
        case "Networking":
            return "Networking";
        case "Miscellaneous":
            return "Vermischtes";
        default:
            return "";
    }
};
export const kindToBackgroundColor = (kind) => {
    switch (kind) {
        case "Movie":
            return "rgb(238,145,51)";
        case "Cabaret":
            return "#97be3e";
        case "Music":
            return "#fdd344";
        case "Dance":
            return "#268b3f";
        case "Theater":
            return "#dd1b29";
        case "Exhibition":
            return "#2c9691";
        case "GuidedTour":
            return "#302f80";
        case "Course":
            return "#53abdc";
        case "Literature":
            return "#2274b3";
        case "Science":
            return "#374e95";
        case "Food":
            return "#642e81";
        case "Leisure":
            return "#912a7f";
        case "Party":
            return "#cf1f54";
        case "Sport":
            return "#de2175";
        case "Networking":
            return "#e981af";
        case "Miscellaneous":
            return "#565656";
        default:
            return "#000000";
    }
};
